import React from "react";

import Hourly from "./Hourly";
import Window from "./Window";
import Forecast from "./model/forecast";

interface HourlyWindowProps {
  forecast?: Forecast;
  tz: string;
  unit: string;
}

const HourlyWindow = ({ forecast, tz, unit }: HourlyWindowProps) => (
  <Window title="Today">
    <Hourly forecast={forecast} tz={tz} unit={unit} />
  </Window>
);

export default HourlyWindow;
