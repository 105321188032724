import React from "react";

import CurrentConditions from "./CurrentConditions";
import Window from "./Window";

interface CurrentWindowProps {
  zone: string;
}

const CurrentWindow = ({ zone }: CurrentWindowProps) => (
  <Window title="Right Now">
    <CurrentConditions zone={zone} />
  </Window>
);

export default CurrentWindow;
