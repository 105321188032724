import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React from "react";

import useZones from "./useZones";

interface SettingsDrawerProps {
  onSetOpen: (open: boolean) => void;
  onSetZone: (zone: string) => void;
  open: boolean;
  zone: string;
}

const SettingsDrawer = ({
  onSetOpen,
  onSetZone,
  open,
  zone,
}: SettingsDrawerProps) => {
  const zones = useZones();

  const handleClick = (toSet: string) => {
    onSetZone(toSet);
    onSetOpen(false);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => onSetOpen(false)}
      onOpen={() => onSetOpen(true)}
    >
      <Box width={325} sx={{ overflowX: "hidden" }}>
        <List
          subheader={<ListSubheader>Select Your Jawn</ListSubheader>}
          disablePadding
        >
          {zones.map((z, idx) => (
            <ListItem
              key={z.value + idx}
              selected={zone === z.value}
              disablePadding
              divider
            >
              <ListItemButton onClick={() => handleClick(z.value)}>
                <ListItemIcon>{z.flag}</ListItemIcon>
                <ListItemText primary={z.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default SettingsDrawer;
