import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import * as _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React from "react";

import ConditionIcon from "./ConditionIcon";
import Forecast from "./model/forecast";
import { Hour } from "./model/forecast";
import { Colors } from "./theme";

const FlexBox = styled(Box)(({ theme }) => ({
  background: Colors.primaryBackground,
  borderRadius: theme.shape.borderRadius,
  color: Colors.primaryForeground,
  fontSize: 16,
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

interface DailyTotalsProps {
  forecast?: Forecast;
  tz: string;
  unit: string;
}

const DailyTotals = ({ forecast, tz, unit }: DailyTotalsProps) => {
  if (!forecast || forecast.days.length < 2) {
    return null;
  }

  const twoDays = [...forecast.days[0].hours, ...forecast.days[1].hours];

  const start = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: tz,
    }),
  ).getHours();
  const hours = twoDays.slice(start, start + 12);

  const maxGusts = _.maxBy(hours, (hour: Hour) => hour.windgust);

  if (!maxGusts) {
    return null;
  }

  const totalRain = _.reduce(
    hours,
    (sum: number, hour: Hour) => sum + hour.precip,
    0,
  );

  const totalSnow = _.reduce(
    hours,
    (sum: number, hour: Hour) => sum + hour.snow,
    0,
  );

  const hourName = (dt: number) => moment.unix(dt).tz(tz).format("hA");

  const rain = () => {
    if (totalRain < 0.1) {
      const hr = _.maxBy(hours, (hour: Hour) => hour.precipprob);

      if (!hr || hr.precipprob < 30) {
        return "Not much chance for precipitation today!";
      }

      return Math.round(hr.precipprob) + "% chance of rain in a few hours.";
    }

    return (
      totalRain.toFixed(2) +
      (unit === "F" ? "in" : "cm") +
      " rain in the next 12 hours."
    );
  };

  const snow =
    totalSnow.toFixed(2) +
    (unit === "F" ? "in" : "cm") +
    " snow in the next 12 hours.";

  const Precip = () => {
    if (totalSnow > 0.1) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <ConditionIcon condition="snow" sx={{ fontSize: 20 }} />
          </Grid>
          <Grid item xs={10}>
            {snow}
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <ConditionIcon condition="rain" sx={{ fontSize: 20 }} />
        </Grid>
        <Grid item xs={10}>
          {rain()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <FlexBox>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <ConditionIcon condition="wind" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10}>
              {Math.round(maxGusts.windgust) + (unit === "F" ? "mph" : "kph")}{" "}
              wind gusts around {hourName(maxGusts.datetimeEpoch)}.
            </Grid>
          </Grid>
        </FlexBox>
        <FlexBox>
          <Precip />
        </FlexBox>
      </Box>
    </Box>
  );
};

export default DailyTotals;
