import Grid from "@mui/material/Grid";
import React from "react";

import WeatherIcon from "./WeatherIcon";
import { Day } from "./model/forecast";
import { Hour } from "./model/forecast";

interface WindIconProps {
  interval: Day | Hour;
  sx?: React.CSSProperties;
  unit: string;
}

const WindIcon = ({ interval, sx }: WindIconProps) => {
  const wind = Math.round(interval.windspeed);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={1}
      wrap="nowrap"
    >
      <Grid item>
        <WeatherIcon
          icon={`wi-wind from-${interval.winddir}-deg`}
          sx={{ fontSize: 20 }}
        />
      </Grid>
      <Grid item>{wind}</Grid>
    </Grid>
  );
};

export default WindIcon;
