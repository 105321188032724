const useZones = () => {
  return [
    {
      value: "ALB",
      label: "Albany, NY",
      short: "Albany",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "AMS",
      label: "Amsterdam, NL",
      short: "Amsterdam",
      units: "metric",
      flag: "🇳🇱",
      tz: "Europe/Amsterdam",
    },
    {
      value: "AUS",
      label: "Austin, TX",
      short: "Austin",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Chicago",
    },
    {
      value: "BAL",
      label: "Baltimore, MD",
      short: "Baltimore",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "BOS",
      label: "Boston, MA",
      short: "Boston",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "BUF",
      label: "Buffalo, NY",
      short: "Buffalo",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "BUR",
      label: "Burlington, VT",
      short: "Burlington",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "CHI",
      label: "Chicago, IL",
      short: "Chicago",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Chicago",
    },
    {
      value: "CBL",
      label: "Cobleskill, NY",
      short: "Cobleskill",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "DAL",
      label: "Dallas, TX",
      short: "Dallas",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Chicago",
    },
    {
      value: "DON",
      label: "Drayton, ON",
      short: "Drayton",
      units: "metric",
      flag: "🇨🇦",
      tz: "America/New_York",
    },
    {
      value: "FCH",
      label: "Falls Church, VA",
      short: "Falls Church",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "FPT",
      label: "Fairport, NY",
      short: "Fairport",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "HOU",
      label: "Houston, TX",
      short: "Houston",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Chicago",
    },
    {
      value: "HLT",
      label: "Hilton Head, SC",
      short: "Hilton Head",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "WMA",
      label: "Lanesboro, MA",
      short: "Lanesboro",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "LON",
      label: "London, UK",
      short: "London",
      units: "metric",
      flag: "🇬🇧",
      tz: "GMT",
    },
    {
      value: "LOS",
      label: "Los Angeles, CA",
      short: "LA",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Los_Angeles",
    },
    {
      value: "MLF",
      label: "Milford, CT",
      short: "Milford",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "MQC",
      label: "Montreal, QC",
      short: "Montreal",
      units: "metric",
      flag: "🇨🇦",
      tz: "America/New_York",
    },
    {
      value: "PHL",
      label: "Philadelphia, PA",
      short: "Philly",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "PHX",
      label: "Phoenix, AZ",
      short: "Phoenix",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Phoenix",
    },
    {
      value: "NYC",
      label: "New York, NY",
      short: "NYC",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "ROC",
      label: "Rochester, NY",
      short: "Roc City",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "SAL",
      label: "Salem, MA",
      short: "🦇 Witch City 🧙",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "SANAN",
      label: "San Antonio, TX",
      short: "San Antonio",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Chicago",
    },
    {
      value: "SAN",
      label: "San Diego, CA",
      short: "San Diego",
      units: "us",
      flag: "🇺🇸",
      tz: "America/Los_Angeles",
    },
    {
      value: "STW",
      label: "Stowe, VT",
      short: "Stowe",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
    {
      value: "TON",
      label: "Toronto, ON",
      short: "Toronto",
      units: "metric",
      flag: "🇨🇦",
      tz: "America/New_York",
    },
    {
      value: "TOK",
      label: "Tokyo, JP",
      short: "Tokyo",
      units: "metric",
      flag: "🇯🇵",
      tz: "Asia/Tokyo",
    },
    {
      value: "WEB",
      label: "Webster, NY",
      short: "Webster",
      units: "us",
      flag: "🇺🇸",
      tz: "America/New_York",
    },
  ];
};

export default useZones;
