export interface Alert {
  Description: string;
  EndsEpoch: number;
  Event: string;
  Link: string;
  OnsetEpoch: number;
}

export const fromApiFormat = (data: any) => {
  const alerts: Alert[] = [];

  if (!data || !data.alerts) {
    return alerts;
  }

  data.alerts.forEach((alert: any) => {
    alerts.push({
      Description: alert.description,
      EndsEpoch: alert.endsEpoch,
      Event: alert.event,
      Link: alert.link,
      OnsetEpoch: alert.onsetEpoch,
    });
  });

  return alerts;
};

export default Alert;
