import React from "react";

import Daily from "./Daily";
import Window from "./Window";
import Forecast from "./model/forecast";

interface DailyWindowProps {
  forecast?: Forecast;
  tz: string;
  unit: string;
}

const DailyWindow = ({ forecast, tz, unit }: DailyWindowProps) => (
  <Window title="This Week">
    <Daily forecast={forecast} tz={tz} unit={unit} />
  </Window>
);

export default DailyWindow;
