import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React from "react";

import Hours from "./Hours";
import Loader from "./Loader";
import Forecast from "./model/forecast";
import { Colors } from "./theme";

const HourlyBox = styled(Box)(({ theme }) => ({
  background: Colors.primaryBackground,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
}));

const addHours = (numOfHours: number, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
};

interface HourlyProps {
  forecast?: Forecast;
  tz: string;
  unit: string;
}

const Hourly = ({ forecast, tz, unit }: HourlyProps) => {
  if (!forecast || forecast.days.length === 0) {
    return (
      <>
        <Loader />
      </>
    );
  }

  const threeDays = [
    ...forecast.days[0].hours,
    ...forecast.days[1].hours,
    ...forecast.days[2].hours,
  ];

  const start = new Date(
    addHours(1).toLocaleString("en-US", {
      timeZone: tz,
    }),
  ).getHours();
  const hours = threeDays.slice(start, start + 24);

  return (
    <HourlyBox p={2}>
      <Hours hours={hours} tz={tz} unit={unit} />
    </HourlyBox>
  );
};

export default Hourly;
