import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import React from "react";
import useSWR from "swr";
import fetch from "unfetch";

import Icon from "./Icon";
import Loader from "./Loader";
import ConditionsResponse from "./model/conditions";
import { CurrentConditions as CurrentConditionsModel } from "./model/conditions";
import { Colors } from "./theme";
import { Fonts } from "./theme";
import useZones from "./useZones";

const TempBox = styled(Box)({
  color: Colors.secondaryForeground,
  fontFamily: Fonts.heading,
  fontWeight: 500,
  fontSize: 60,
  lineHeight: 1,
});

const ConditionsBox = styled(Box)(({ theme }) => ({
  background: Colors.primaryBackground,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  color: Colors.primaryForeground,
  fontSize: 20,
  textAlign: "center",
  textShadow: "3px 3px 0 rgb(0, 0, 0, .1)",
}));

interface ConditionsProps {
  conditions: CurrentConditionsModel;
}

const Conditions = ({ conditions }: ConditionsProps) => (
  <ConditionsBox>
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Grid item>
        <Box display="flex" alignItems="center">
          <Icon animated name={conditions.icon} />
        </Box>
      </Grid>
      <Grid item>
        <TempBox>{Math.round(conditions.temp!)}°</TempBox>
        <Box>Feels like {Math.round(conditions.feelslike!)}°</Box>
      </Grid>
    </Grid>
  </ConditionsBox>
);

const fetcher = (url: string) => fetch(url).then((r) => r.json());

interface CurrentConditionsProps {
  zone: string;
}

const CurrentConditions = ({ zone }: CurrentConditionsProps) => {
  const zones = useZones();
  const zoneOb = zones.find((z) => z.value === zone);
  const units = zoneOb?.units || "us";

  const { data, error } = useSWR(
    `/api/current?zone=${zone}&units=${units}`,
    fetcher,
    { refreshInterval: 180000 },
  );

  if (error) {
    console.error(error);
    return <Loader />;
  }

  let response: ConditionsResponse | undefined = undefined;

  if (data && !error) {
    response = data as ConditionsResponse;
  }

  if (!response) {
    return <Loader />;
  }

  return <Conditions conditions={response.currentConditions} />;
};

export default CurrentConditions;
