import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React from "react";

import Hour from "./Hour";
import { Hour as HourModel } from "./model/forecast";

const HoursBox = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  overflowY: "hidden",
}));

interface HoursProps {
  hours: HourModel[];
  tz: string;
  unit: string;
}

const Hours = ({ hours, tz, unit }: HoursProps) => (
  <HoursBox className="nobar">
    {hours.map((hour: HourModel, idx: number) => (
      <Hour key={idx} hour={hour} tz={tz} unit={unit} />
    ))}
  </HoursBox>
);

export default Hours;
