import React from "react";

import WeatherIcon from "./WeatherIcon";

const conditions = new Map<string, string>([
  ["clear-day", "wi-day-sunny"],
  ["clear-night", "wi-night-clear"],
  ["cloudy", "wi-cloudy"],
  ["fog", "wi-fog"],
  ["hail", "wi-hail"],
  ["partly-cloudy-day", "wi-day-cloudy"],
  ["partly-cloudy-night", "wi-night-partly-cloudy"],
  ["rain-snow-showers-day", "wi-day-showers"],
  ["rain-show-showers-night", "wi-night-showers"],
  ["rain-snow", "wi-rain-mix"],
  ["rain", "wi-rain"],
  ["showers-day", "wi-day-showers"],
  ["showers-night", "wi-night-showers"],
  ["sleet", "wi-sleet"],
  ["snow-showers-day", "wi-day-snow"],
  ["snow-showers-night", "wi-night-snow"],
  ["snow", "wi-snow"],
  ["thunder-rain", "wi-storm-showers"],
  ["thunder-showers-day", "wi-day-storm-showers"],
  ["thunder-showers-night", "wi-night-storm-showers"],
  ["thunder", "wi-thunderstorm"],
  ["wind", "wi-strong-wind"],
  ["precip-rain", "wi-raindrop"],
  ["precip-snow", "wi-snowflake-cold"],
]);

interface ConditionIconProps {
  condition: string;
  sx?: React.CSSProperties;
}

const ConditionIcon = ({ condition, sx }: ConditionIconProps) => {
  const getConditionTranslation = () => conditions.get(condition) || "wi-na";
  return <WeatherIcon icon={getConditionTranslation()} sx={sx} />;
};

export default ConditionIcon;
