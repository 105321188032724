import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Chart } from "chart.js";
import React from "react";

import Window from "./Window";

Chart.defaults.font.family =
  "'Share Tech', 'Helvetica Neue', Arial, sans-serif";
Chart.defaults.font.size = 16;

const ChartBox = styled(Box)(({ theme }) => ({
  background: "#ffffff",
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
}));

interface ChartWindowProps {
  children?: React.ReactNode;
  title: string;
}

const ChartWindow = ({ children, title }: ChartWindowProps) => (
  <Window title={title}>
    <ChartBox>{children}</ChartBox>
  </Window>
);

export default ChartWindow;
