import Box from "@mui/material/Box";
import React from "react";

const conditions = new Map<string, string>([
  ["clear-day", "clear-day"],
  ["clear-night", "clear-night"],
  ["cloudy", "cloudy"],
  ["fog", "fog"],
  ["hail", "hail"],
  ["partly-cloudy-day", "partly-cloudy-day"],
  ["partly-cloudy-night", "partly-cloudy-night"],
  ["rain-snow-showers-day", "sleet"],
  ["rain-show-showers-night", "sleet"],
  ["rain-snow", "sleet"],
  ["rain", "rain"],
  ["showers-day", "overcast-day-rain"],
  ["showers-night", "overcast-night-rain"],
  ["sleet", "sleet"],
  ["snow-showers-day", "overcast-day-snow"],
  ["snow-showers-night", "overcast-night-snow"],
  ["snow", "snow"],
  ["thunder-rain", "thunderstorms-rain"],
  ["thunder-showers-day", "thunderstorms-day-rain"],
  ["thunder-showers-night", "thunderstorms-night-rain"],
  ["thunder", "thunderstorms"],
  ["wind", "wi-strong-wind"],
  ["precip-rain", "wind"],
  ["precip-snow", "snow"],
]);

interface IconProps {
  animated?: boolean;
  name: string;
  width?: number;
}

const Icon = ({ animated = false, name, width = 125 }: IconProps) => {
  const partialPath = animated ? "svg" : "svg-static";

  return (
    <Box width={width} height={width} overflow="hidden">
      <img
        src={`/weather-icons/production/fill/${partialPath}/${conditions.get(
          name,
        )}.svg`}
        alt={name}
        width={width}
        height={width}
      />
    </Box>
  );
};

export default Icon;
