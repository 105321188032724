import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import * as _ from "lodash";
import React from "react";

import ConditionIcon from "./ConditionIcon";
import Forecast from "./model/forecast";
import { Day } from "./model/forecast";
import { Colors } from "./theme";

const FlexBox = styled(Box)(({ theme }) => ({
  background: Colors.primaryBackground,
  borderRadius: theme.shape.borderRadius,
  color: Colors.primaryForeground,
  fontSize: 16,
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

interface WeeklyTotalsProps {
  forecast?: Forecast;
  tz: string;
  unit: string;
}

const WeeklyTotals = ({ forecast, tz, unit }: WeeklyTotalsProps) => {
  if (!forecast) {
    return null;
  }

  const getDays = () => {
    if (forecast.days.length < 7) {
      return forecast.days;
    }

    return forecast.days.slice(0, 7);
  };

  const dayName = (epoch: number) => {
    const theDate = new Date(epoch * 1000);
    return theDate.toLocaleString("en-us", { timeZone: tz, weekday: "long" });
  };

  const maxGusts = _.maxBy(getDays(), (day: Day) => day.windgust);

  if (!maxGusts) {
    return null;
  }

  const totalRain = _.reduce(
    getDays(),
    (sum: number, day: Day) => sum + day.precip,
    0,
  );

  const totalSnow = _.reduce(
    getDays(),
    (sum: number, day: Day) => sum + day.snow,
    0,
  );

  const snow = () => {
    if (totalSnow < 0.01) {
      return "No";
    }

    return totalSnow.toFixed(2) + (unit === "F" ? "in" : "cm");
  };

  const rain = () => {
    if (totalRain < 0.01) {
      return "No";
    }

    return totalRain.toFixed(2) + (unit === "F" ? "in" : "cm");
  };

  const Precip = () => {
    if (totalSnow >= 0.1) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <ConditionIcon condition="snow" sx={{ fontSize: 20 }} />
          </Grid>
          <Grid item xs={10}>
            {snow()} snow in the next 7 days.
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={2} sx={{ textAlign: "center" }}>
          <ConditionIcon condition="rain" sx={{ fontSize: 20 }} />
        </Grid>
        <Grid item xs={10}>
          {rain()} rain in the next 7 days.
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <FlexBox>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <ConditionIcon condition="wind" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10}>
              {Math.round(maxGusts.windgust) + (unit === "F" ? "mph" : "kph")}{" "}
              wind gusts on {dayName(maxGusts.datetimeEpoch)}.
            </Grid>
          </Grid>
        </FlexBox>
        <FlexBox>
          <Precip />
        </FlexBox>
      </Box>
    </Box>
  );
};

export default WeeklyTotals;
