import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import "moment-timezone";
import React from "react";

import ConditionIcon from "./ConditionIcon";
import PrecipIcon from "./PrecipIcon";
import WindIcon from "./WindIcon";
import { Hour as HourModel } from "./model/forecast";
import { Colors } from "./theme";
import { Fonts } from "./theme";

const HourBox = styled(Box)(({ theme }) => ({
  color: Colors.primaryForeground,
  flex: "0 0 calc(25% - 12px)",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    flex: "0 0 calc(20% - 12px)",
  },
}));

const CurrentHourBox = styled(Box)({
  fontFamily: Fonts.heading,
  fontSize: 22,
  fontWeight: 500,
});

const img = {
  fontSize: 40,
  lineHeight: 1,
} as const;

const TempBox = styled(CurrentHourBox)({
  color: Colors.secondaryForeground,
});

interface HourProps {
  hour: HourModel;
  tz: string;
  unit: string;
}

const Hour = ({ hour, tz, unit }: HourProps) => {
  const currentHour = moment.unix(hour.datetimeEpoch).tz(tz).format("hA");

  return (
    <HourBox mr={2}>
      <CurrentHourBox>{currentHour}</CurrentHourBox>
      <Box pb={1} pt={2}>
        <ConditionIcon condition={hour.icon} sx={img} />
      </Box>
      <TempBox>
        {Math.round(hour.temp!)}°{unit}
      </TempBox>
      <Box>
        <PrecipIcon interval={hour} />
      </Box>
      <Box>
        <WindIcon interval={hour} unit={unit} />
      </Box>
    </HourBox>
  );
};

export default Hour;
