import React from "react";

import ConditionIcon from "./ConditionIcon";
import { Hour } from "./model/forecast";
import { Day } from "./model/forecast";

interface PrecipIconProps {
  interval: Day | Hour;
  sx?: React.CSSProperties;
}

const PrecipIcon = ({ interval, sx }: PrecipIconProps) => {
  const precipIcon = () => {
    if (interval.snow && interval.snow > 0) {
      return <ConditionIcon condition="precip-snow" sx={sx} />;
    }

    return <ConditionIcon condition="precip-rain" sx={sx} />;
  };

  const precipProb = () => {
    return Math.round(interval.precipprob!) + "%";
  };

  return (
    <>
      {precipProb()}&nbsp;
      {precipIcon()}
    </>
  );
};

export default PrecipIcon;
