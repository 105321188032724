import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import moment from "moment";
import "moment-timezone";
import React from "react";

import AlertModel from "./model/alerts";
import { Colors } from "./theme";

interface AlertProps {
  alert: AlertModel;
  tz: string;
}

const AlertAccordion = styled(Accordion)({
  backgroundColor: Colors.alertBackgroundColor,
  textShadow: "1px 1px 0 rgba(0,0,0,.8)",
});

const AlertTitle = styled(Typography)({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 10,
});

const Alert = ({ alert, tz }: AlertProps) => {
  const formatAlertTime = (time: number) =>
    moment.unix(time).tz(tz).format("h:mm a on dddd MMM D, yyyy");

  return (
    <AlertAccordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AlertTitle>
          <WarningIcon /> {alert.Event}
        </AlertTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Typography paragraph={true} variant="body2">
          <strong>Issued:</strong> {formatAlertTime(alert.OnsetEpoch)}.
        </Typography>
        <Typography paragraph={true} variant="body2">
          <strong>Expires:</strong> {formatAlertTime(alert.EndsEpoch)}.
        </Typography>
        <Typography paragraph={true} variant="body2">
          {alert.Description}
        </Typography>
        <Typography paragraph={true} variant="body2">
          <Link href={alert.Link}>More info</Link>
        </Typography>
      </AccordionDetails>
    </AlertAccordion>
  );
};

export default Alert;
