import React from "react";

import Days from "./Days";
import ForecastModel from "./model/forecast";

interface ForecastProps {
  forecast?: ForecastModel;
  tz: string;
  unit: string;
}

const Forecast = ({ forecast, tz, unit }: ForecastProps) => {
  if (!forecast) {
    return null;
  }

  const getDays = () => {
    if (forecast.days.length < 7) {
      return forecast.days;
    }

    return forecast.days.slice(0, 7);
  };

  return <Days days={getDays()} tz={tz} unit={unit} />;
};

export default Forecast;
