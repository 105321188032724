import React from "react";

interface WeatherIconProps {
  icon: string;
  sx?: React.CSSProperties;
}

const WeatherIcon = ({ icon, sx }: WeatherIconProps) => {
  return <i className={"wi " + icon} style={sx}></i>;
};

export default WeatherIcon;
