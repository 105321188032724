import Box from "@mui/material/Box";
import React from "react";
import useSWR from "swr";
import fetch from "unfetch";

import Alert from "./Alert";
import AlertModel from "./model/alerts";
import { fromApiFormat } from "./model/alerts";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

interface AlertsWindowProps {
  tz: string;
  zone: string;
}

const AlertsWindow = ({ tz, zone }: AlertsWindowProps) => {
  const { data, error } = useSWR(`/api/alerts?zone=${zone}`, fetcher, {
    refreshInterval: 180000,
  });

  if (error) {
    console.error(error);
    return null;
  }

  const alerts = fromApiFormat(data);

  if (alerts.length === 0) {
    return null;
  }

  return (
    <>
      {alerts.map((alert: AlertModel, idx: number) => (
        <Box key={"alert-" + idx}>
          <Alert alert={alert} tz={tz} />
        </Box>
      ))}
    </>
  );
};

export default AlertsWindow;
