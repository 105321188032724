import Box from "@mui/material/Box";
import { Chart as ChartJS } from "chart.js";
import { CategoryScale } from "chart.js";
import { Legend } from "chart.js";
import { LinearScale } from "chart.js";
import { LineElement } from "chart.js";
import { PointElement } from "chart.js";
import { Title } from "chart.js";
import { Tooltip } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

import Loader from "./Loader";
import Forecast from "./model/forecast";
import { Day } from "./model/forecast";
import { Colors } from "./theme";
import useDaysOfWeek from "./useDaysOfWeek";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface PressureProps {
  forecast?: Forecast;
  unit: string;
}

const Pressure = ({ forecast, unit }: PressureProps) => {
  var labels = useDaysOfWeek();

  if (!forecast || !labels) {
    return <Loader />;
  }

  const days =
    forecast.days.length < 7 ? forecast.days : forecast.days.slice(0, 7);

  const data = {
    labels,
    datasets: [
      {
        label: "Pressure",
        data: days.map((day: Day) => day.pressure),
        borderColor: Colors.chartColorOne,
        backgroundColor: Colors.chartColorOne,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx: any) => `${ctx.parsed.y}° ${unit}`,
        },
      },
    },
    responsive: true,
    scales: {
      x: {},
      y: {
        title: {
          display: true,
          text: "Pressure in mbar",
        },
      },
    },
  };

  return (
    <Box p={1}>
      <Line options={options} data={data} />
    </Box>
  );
};

export default Pressure;
