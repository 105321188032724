import Stack from "@mui/material/Stack";
import React from "react";

import Day from "./Day";
import { Day as DayModel } from "./model/forecast";

interface DaysProps {
  days: DayModel[];
  tz: string;
  unit: string;
}

const Days = ({ days, tz, unit }: DaysProps) => (
  <Stack>
    {days.map((day: DayModel, idx: number) => (
      <Day idx={idx} key={idx} day={day} tz={tz} unit={unit} />
    ))}
  </Stack>
);

export default Days;
