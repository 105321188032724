import { createTheme } from "@mui/material/styles";

import { DefaultTheme } from "./themes";
import { Theme } from "./themes";
import { Themes } from "./themes";

const selectedTheme = localStorage.getItem("theme");
const storedTheme =
  selectedTheme !== null ? JSON.parse(selectedTheme) : "default";
const getConfiguredTheme = () => Themes.get(storedTheme) ?? DefaultTheme;
export const Colors: Theme = getConfiguredTheme();

export const Fonts = {
  heading: "'Eurostile', 'Share Tech', sans-serif",
  body: "'Share Tech', 'Helvetica Neue', Arial, sans-serif",
};

const appTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: Colors.primaryForeground,
    },
    action: {
      active: Colors.linkColor,
      hover: Colors.linkColor,
      focus: Colors.linkColor,
      selected: Colors.linkColor,
    },
  },
  typography: {
    fontFamily: Fonts.body,
    fontSize: 18,
    button: {
      textTransform: "none",
    },
  },
});

export default appTheme;
