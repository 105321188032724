import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import moment from "moment";
import "moment-timezone";
import React from "react";

import WeatherIcon from "./WeatherIcon";
import Forecast from "./model/forecast";
import { Colors } from "./theme";

const AstronomyBox = styled(Box)(({ theme }) => ({
  background: Colors.primaryBackground,
  borderRadius: theme.shape.borderRadius,
  color: Colors.primaryForeground,
  fontSize: 16,
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const getPhase = (phase: number) => {
  if (phase === 0) {
    return ["wi-moon-new", "new"];
  }

  if (phase > 0 && phase < 0.25) {
    return ["wi-moon-waxing-crescent-1", "waxing crescent"];
  }

  if (phase === 0.25) {
    return ["wi-moon-first-quarter", "first quarter"];
  }

  if (phase > 0.25 && phase < 0.5) {
    return ["wi-moon-waxing-gibbous-1", "waxing gibbous"];
  }

  if (phase === 0.5) {
    return ["wi-moon-full", "full"];
  }

  if (phase > 0.5 && phase < 0.75) {
    return ["wi-moon-waning-gibbous-1", "waning gibbous"];
  }

  if (phase === 0.75) {
    return ["wi-moon-third-quarter", "last quarter"];
  }

  return ["wi-moon-waning-crescent-1", "waning crescent"];
};

interface AstronomyWindowProps {
  forecast?: Forecast;
  tz: string;
}

const AstronomyWindow = ({ forecast, tz }: AstronomyWindowProps) => {
  if (!forecast) {
    return null;
  }

  const day = forecast.days[0];

  const phase = getPhase(day.moonphase!);

  const convertToLocal = (epoch: number) => {
    return moment.unix(epoch).tz(tz).format("h:mmA");
  };

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <AstronomyBox>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <WeatherIcon icon="wi-sunrise" sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10}>
              The sun rises at {convertToLocal(day.sunriseEpoch)} and sets at{" "}
              {convertToLocal(day.sunsetEpoch)}.
            </Grid>
          </Grid>
        </AstronomyBox>
        <AstronomyBox>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <WeatherIcon icon={phase[0]} sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item xs={10}>
              Wow, it's a {phase[1]} moon tonight!
            </Grid>
          </Grid>
        </AstronomyBox>
      </Box>
    </Box>
  );
};

export default AstronomyWindow;
