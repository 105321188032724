import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";

interface ThemeDialogProps {
  currentTheme: string;
  onClose: () => void;
  onSetTheme: (value: string) => void;
  open?: boolean;
}

const ThemeDialog = ({
  currentTheme,
  onClose,
  onSetTheme,
  open = false,
}: ThemeDialogProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    onSetTheme(event.target.value as string);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select a Theme</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Weather Jawn now comes in multiple flavors, choose wisely!
        </DialogContentText>
        <Box mt={4}>
          <FormControl fullWidth>
            <InputLabel id="theme-select-label">Theme</InputLabel>
            <Select
              labelId="theme-select-label"
              value={currentTheme}
              label="Theme"
              onChange={handleChange}
            >
              <MenuItem value="autumn">Autumn</MenuItem>
              <MenuItem value="default">Original Jawn</MenuItem>
              <MenuItem value="steam">Steam 2005</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThemeDialog;
