import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import * as React from "react";

import { Colors } from "./theme";

const LoaderBox = styled(Box)(({ theme }) => ({
  background: Colors.primaryBackground,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(5),
}));

const Loader = () => (
  <LoaderBox>
    <CircularProgress />
  </LoaderBox>
);

export default Loader;
