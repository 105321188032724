import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React from "react";

import { Colors } from "./theme";
import { Fonts } from "./theme";

const WindowBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.windowTitleBackground,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  color: Colors.primaryForeground,
  fontFamily: Fonts.heading,
  fontSize: 20,
  fontWeight: 500,
  padding: "7px",
  paddingBottom: "3px",
  textShadow: "1px 1px 2px rgb(0, 0, 0, .2)",
  textTransform: "uppercase",
}));

interface WindowProps {
  children?: React.ReactNode;
  title: string;
}

const Window = ({ children, title }: WindowProps) => (
  <WindowBox>
    <TitleBox>{title}</TitleBox>
    {children}
  </WindowBox>
);

export default Window;
