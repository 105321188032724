import Box from "@mui/material/Box";
import { BarElement } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { CategoryScale } from "chart.js";
import { Legend } from "chart.js";
import { LinearScale } from "chart.js";
import { Title } from "chart.js";
import { Tooltip } from "chart.js";
import * as _ from "lodash";
import React from "react";
import { Line } from "react-chartjs-2";

import Loader from "./Loader";
import Forecast from "./model/forecast";
import { Day } from "./model/forecast";
import { Colors } from "./theme";
import useDaysOfWeek from "./useDaysOfWeek";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface PrecipProps {
  forecast?: Forecast;
  unit: string;
}

const Precip = ({ forecast, unit }: PrecipProps) => {
  var labels = useDaysOfWeek();

  if (!forecast || !labels) {
    return <Loader />;
  }

  const days =
    forecast.days.length < 7 ? forecast.days : forecast.days.slice(0, 7);

  const totalSnow = _.reduce(
    days,
    (sum: number, day: Day) => sum + day.snow,
    0,
  );
  const isSnow = totalSnow >= 0.1;

  const getSnow = () => {
    if (!isSnow) {
      return [];
    }

    return [
      {
        label: "Snow",
        data: days.map((day: Day) => day.snow),
        borderColor: Colors.chartColorTwo,
        backgroundColor: Colors.chartColorTwo,
      },
    ];
  };

  const data = {
    labels,
    datasets: [
      {
        label: isSnow ? "Liquid" : "Rain",
        data: days.map((day: Day) => day.precip),
        borderColor: Colors.chartColorOne,
        backgroundColor: Colors.chartColorOne,
      },
      ...getSnow(),
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx: any) =>
            `${ctx.parsed.y} ` + (unit === "F" ? "in" : "cm"),
        },
      },
    },
    responsive: true,
    scales: {
      x: {},
      y: {
        title: {
          display: true,
          text: (unit === "F" ? "in" : "cm") + " precipitation",
        },
      },
    },
  };

  return (
    <Box p={1}>
      <Line options={options} data={data} />
    </Box>
  );
};

export default Precip;
