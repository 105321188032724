import Box from "@mui/material/Box";
import React from "react";

import Forecast from "./Forecast";
import Loader from "./Loader";
import ForecastModel from "./model/forecast";

interface DailyProps {
  forecast?: ForecastModel;
  tz: string;
  unit: string;
}

const Daily = ({ forecast, tz, unit }: DailyProps) => {
  if (!forecast) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <Box>
      <Forecast forecast={forecast} tz={tz} unit={unit} />
    </Box>
  );
};

export default Daily;
