import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

const useDaysOfWeek = () => {
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>();

  useEffect(() => {
    // Probably not the cleanest way to do all this...
    var days = [moment().format("ddd")];
    for (var i = 1; i < 7; i++) {
      days.push(moment().add(i, "days").format("ddd"));
    }

    setDaysOfWeek(days);
  }, []);

  return daysOfWeek;
};

export default useDaysOfWeek;
