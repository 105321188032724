import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useSWR from "swr";
import fetch from "unfetch";

import AlertsWindow from "./AlertsWindow";
import AstronomyWindow from "./AstronomyWindow";
import ChartWindow from "./ChartWindow";
import CloudCover from "./CloudCover";
import CurrentWindow from "./CurrentWindow";
import DailyTotals from "./DailyTotals";
import DailyWindow from "./DailyWindow";
import Dewpoint from "./Dewpoint";
import HourlyWindow from "./HourlyWindow";
import Humidity from "./Humidity";
import Precip from "./Precip";
import Pressure from "./Pressure";
import SettingsDrawer from "./SettingsDrawer";
import Temp from "./Temp";
import ThemeDialog from "./ThemeDialog";
import UV from "./UV";
import WeeklyTotals from "./WeeklyTotals";
import Wind from "./Wind";
import Forecast from "./model/forecast";
import AppTheme from "./theme";
import { Colors } from "./theme";
import { Fonts } from "./theme";
import useLocalStorage from "./useLocalStorage";
import useZones from "./useZones";

const fetcher = (url: string) => fetch(url).then((r) => r.json());

const HeaderGrid = styled(Grid)({
  color: Colors.primaryForeground,
  fontFamily: Fonts.heading,
  fontSize: 22,
  fontWeight: 500,
  height: 18,
  lineHeight: 1,
  textDecoration: "none",
  textTransform: "uppercase",
});

const Footer = styled(Box)({
  fontFamily: Fonts.heading,
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 1,
  textShadow: "3px 3px 0 rgb(0, 0, 0, .1)",
  textTransform: "uppercase",
});

const App = () => {
  const appGlobals = (window as any).$WeatherJawn || null;
  useEffect(() => {
    document.documentElement.style.backgroundColor = Colors.appBackground;
    document.body.style.background = Colors.appBackgroundGradient;
    document.body.style.color = Colors.bodyForeground;
    document.body.style.fontFamily = Fonts.body;
  }, []);

  const [notesOpen, setNotesOpen] = React.useState(false);
  const [theme, setTheme] = useLocalStorage("theme", "default");
  const [themeDialogOpen, setThemeDialogOpen] = React.useState(false);
  const [zone, setZone] = useLocalStorage("forecastZone", "PHL");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [zone]);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [nerdy, setNerdy] = useLocalStorage("showCharts", false);
  const [showReloadDialog, setShowReloadDialog] = useState(false);

  const { data: versionResponse, error: err } = useSWR(
    "/api/version",
    fetcher,
    {
      refreshInterval: 120000,
    },
  );

  if (err) {
    console.error(err);
  }

  useEffect(() => {
    if (!versionResponse) {
      return;
    }

    if (appGlobals.version.toString() !== versionResponse.version) {
      setShowReloadDialog(true);
    }
  }, [versionResponse, appGlobals.version]);

  const zones = useZones();
  const tz = zones.find((z) => z.value === zone)?.tz || "America/New_York";
  const units = zones.find((z) => z.value === zone)?.units || "us";
  const zoneName = zones.find((z) => z.value === zone)?.short || "";
  const unit = units === "metric" ? "C" : "F";

  const { data, error: forecastErr } = useSWR(
    `/api/forecast?zone=${zone}&units=${units}`,
    fetcher,
    { refreshInterval: 180000 },
  );

  if (forecastErr) {
    console.error(forecastErr);
  }

  let forecast: Forecast | undefined = undefined;

  if (data && !forecastErr) {
    forecast = data as Forecast;
  }

  return (
    <ThemeProvider theme={AppTheme}>
      <Dialog
        open={showReloadDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"🦋 Hey, listen!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There is a new version of Weather Jawn available.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload()}>Update Now</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          style={{ background: Colors.appBarBackground }}
        >
          <Toolbar sx={{ justifyContent: "center" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <HeaderGrid item>{zoneName}</HeaderGrid>
              <Grid item>
                <IconButton
                  aria-label="location"
                  size="small"
                  onClick={() => setSettingsOpen(true)}
                >
                  <EditIcon
                    fontSize="inherit"
                    sx={{ color: Colors.primaryForeground }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <SettingsDrawer
        onSetOpen={setSettingsOpen}
        onSetZone={setZone}
        open={settingsOpen}
        zone={zone}
      />
      <Container maxWidth="sm">
        <Box pb={3} pt={10}>
          <Stack spacing={2}>
            <AlertsWindow zone={zone} tz={tz} />
            <CurrentWindow zone={zone} />
            <HourlyWindow forecast={forecast} tz={tz} unit={unit} />
            <DailyTotals forecast={forecast} tz={tz} unit={unit} />
            <AstronomyWindow forecast={forecast} tz={tz} />
            <DailyWindow forecast={forecast} tz={tz} unit={unit} />
            <WeeklyTotals forecast={forecast} tz={tz} unit={unit} />
            {!nerdy && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setNerdy(true)}
                sx={{
                  backgroundColor: Colors.buttonBackgroundColor,
                  background: Colors.buttonBackgroundGradient,
                  color: Colors.primaryForeground,
                }}
                fullWidth
              >
                Show me the nerdy stuff 🤓
              </Button>
            )}
            {!!nerdy && (
              <>
                <ChartWindow title="Temperature">
                  <Temp forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="Dew Point">
                  <Dewpoint forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="Humidity">
                  <Humidity forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="Wind Speeds">
                  <Wind forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="Cloud Cover">
                  <CloudCover forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="UV Index">
                  <UV forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="Precipitation Depiction">
                  <Precip forecast={forecast} unit={unit} />
                </ChartWindow>
                <ChartWindow title="Barometric Pressure">
                  <Pressure forecast={forecast} unit={unit} />
                </ChartWindow>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setNerdy(false)}
                  sx={{
                    backgroundColor: Colors.buttonBackgroundColor,
                    background: Colors.buttonBackgroundGradient,
                    color: Colors.primaryForeground,
                  }}
                  fullWidth
                >
                  Hide the nerdy stuff 🤓
                </Button>
              </>
            )}
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ color: Colors.primaryForeground, lineHeight: 1 }}
            >
              <Footer>Weather Jawn</Footer>
              <Footer>
                <Link
                  onClick={() => setNotesOpen(true)}
                  sx={{ cursor: "pointer" }}
                >
                  {appGlobals?.version ? `v${appGlobals.version}` : ""}
                </Link>
              </Footer>
              <Box>
                <Box ml={1} mt={-0.5}>
                  <IconButton
                    aria-label="options"
                    color="primary"
                    onClick={() => setThemeDialogOpen(true)}
                  >
                    <SettingsIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={5000}
                open={notesOpen}
                onClose={() => setNotesOpen(false)}
                message={`v${appGlobals.version}: Pick a theme, any theme! As long as it's one of the 3 we have...`}
              />
              <ThemeDialog
                currentTheme={theme}
                open={themeDialogOpen}
                onClose={() => {
                  setThemeDialogOpen(false);
                  window.location.reload();
                }}
                onSetTheme={setTheme}
              />
            </Stack>
          </Stack>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
