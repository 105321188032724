export interface Theme {
  alertBackgroundColor: string;
  appBarBackground: string;
  appBackground: string;
  appBackgroundGradient: string;
  bodyForeground: string;
  buttonBackgroundColor: string;
  buttonBackgroundGradient: string;
  chartColorOne: string;
  chartColorTwo: string;
  dayBackgroundColors: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
  ];
  linkColor: string;
  primaryBackground: string;
  primaryForeground: string;
  secondaryForeground: string;
  windowTitleBackground: string;
}

export const DefaultTheme: Theme = {
  alertBackgroundColor: "#DA6408",
  appBarBackground: "#160E2C",
  appBackground: "#160E2C",
  appBackgroundGradient: "linear-gradient(0deg, #160E2C 0%, #291A54 100%)",
  bodyForeground: "#444444",
  buttonBackgroundColor: "#687FFF",
  buttonBackgroundGradient: "linear-gradient(270deg, #687FFF 0%, #4158EF 100%)",
  chartColorOne: "#989CFF",
  chartColorTwo: "#1421D2",
  dayBackgroundColors: [
    "#989CFF",
    "#8285FF",
    "#687FFF",
    "#5868FF",
    "#4158EF",
    "#353BFE",
    "#1733D0",
    "#1422D2",
  ],
  linkColor: "#363BF4",
  primaryBackground: "#1733D0",
  primaryForeground: "#FFFFFF",
  secondaryForeground: "#FBEB4E",
  windowTitleBackground: "#4158EF",
};

export const AutumnTheme: Theme = {
  alertBackgroundColor: "#DA6408",
  appBarBackground: "#662549",
  appBackground: "#2a1330",
  appBackgroundGradient:
    "linear-gradient(180deg, #100611, #1a0d1c, #211126, #2a1330)",
  bodyForeground: "#444444",
  buttonBackgroundColor: "#F39F5A",
  buttonBackgroundGradient: "#F39F5A",
  chartColorOne: "#662549",
  chartColorTwo: "#AE445A",
  dayBackgroundColors: [
    "#662549",
    "#70294c",
    "#7b2d50",
    "#853152",
    "#8f3555",
    "#9a3a57",
    "#a43f59",
    "#ae445a",
  ],
  linkColor: "#AE445A",
  primaryBackground: "#662549",
  primaryForeground: "#FFFFFF",
  secondaryForeground: "#F39F5A",
  windowTitleBackground: "#AE445A",
};

export const SteamTheme: Theme = {
  alertBackgroundColor: "#DA6408",
  appBarBackground: "#4C5844",
  appBackground: "#3F4718",
  appBackgroundGradient: "#3F4738",
  bodyForeground: "#444444",
  buttonBackgroundColor: "#968732",
  buttonBackgroundGradient: "#968732",
  chartColorOne: "#968732",
  chartColorTwo: "#3F4738",
  dayBackgroundColors: [
    "#4c5844",
    "#526043",
    "#5a6742",
    "#646e3f",
    "#6e753c",
    "#7a7b39",
    "#888136",
    "#968732",
  ],
  linkColor: "#968732",
  primaryBackground: "#4C5844",
  primaryForeground: "#FFFFFF",
  secondaryForeground: "#EEEEEE",
  windowTitleBackground: "#968732",
};

export const Themes = new Map<string, Theme>([
  ["default", DefaultTheme],
  ["autumn", AutumnTheme],
  ["steam", SteamTheme],
]);
