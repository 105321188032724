import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";

import ConditionIcon from "./ConditionIcon";
import PrecipIcon from "./PrecipIcon";
import { Day as DayModel } from "./model/forecast";
import { Colors } from "./theme";
import { Fonts } from "./theme";

const ShortForecastGrid = styled(Grid)(({ theme }) => ({
  maxWidth: 140,
  [theme.breakpoints.up("sm")]: {
    maxWidth: 225,
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 340,
  },
}));

const DayBox = styled(Box)({
  color: Colors.secondaryForeground,
  fontFamily: Fonts.heading,
  fontWeight: 500,
  lineHeight: 1,
  textTransform: "uppercase",
  width: 50,
});

const ShortForecastText = styled(Typography)({
  color: Colors.primaryForeground,
  fontSize: 18,
});

const TempGridItem = styled(Grid)({
  color: Colors.primaryForeground,
  fontFamily: Fonts.heading,
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 1,
  marginLeft: "auto",
  textAlign: "right",
});

const HighBox = styled(Box)({
  color: Colors.secondaryForeground,
});

const img = {
  color: Colors.primaryForeground,
  fontSize: 30,
  lineHeight: 1,
  width: 40,
};

const precip = {
  lineHeight: 1,
};

const lastDay = {
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
};

interface DayProps {
  idx: number;
  day: DayModel;
  tz: string;
  unit: string;
}

const Day = ({ idx, day, tz, unit }: DayProps) => {
  const dayName = (epoch: number) => {
    const theDate = new Date(epoch * 1000);
    return theDate.toLocaleString("en-us", { timeZone: tz, weekday: "short" });
  };

  return (
    <Box
      bgcolor={Colors.dayBackgroundColors[idx]}
      p={1}
      pt={1.5}
      sx={idx === 6 ? lastDay : undefined}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={1}
        wrap="nowrap"
      >
        <Grid item xs="auto">
          <DayBox>{dayName(day.datetimeEpoch!)}</DayBox>
          <Box sx={{ color: Colors.primaryForeground, fontSize: 16 }}>
            <PrecipIcon interval={day} sx={precip} />
          </Box>
        </Grid>
        <Grid item xs="auto">
          <ConditionIcon condition={day.icon} sx={img} />
        </Grid>
        <ShortForecastGrid item xs="auto">
          <ShortForecastText noWrap>
            {day.conditions!.replace("Partially", "Partly")}.
          </ShortForecastText>
        </ShortForecastGrid>
        <TempGridItem item xs="auto">
          <HighBox>
            {Math.round(day.tempmax!)}°{unit}
          </HighBox>
          {Math.round(day.tempmin!)}°{unit}
        </TempGridItem>
      </Grid>
    </Box>
  );
};

export default Day;
